/* styles.css */

.chat-display-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  background-color: transparent;
  border-radius: 20px;
}

.chat-container {
  background: transparent;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.chat-container.closed {
  margin-left: 0; /* Reset margin when sidebar is closed */
}

/* Graph style */
.js-plotly-plot {
  background-color: #333;
  margin-top: 10px;
}

.plot-container {
  background-color: #333;
}

/* Bot Response Styles */
.bot-response-text-content {
  text-align: left;
  margin-top: 10px;
  font-size: 1em;
  line-height: 2.5ch;
}

.bot-response-object {
}

.bot-response-list {
  margin-bottom: 10px;
  font-size: 0.9em;
}

.bot-response-list-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.bot-response-link-button {
  color: rgb(0, 179, 255);
}

.bot-response-list-header {
  font-size: 1em;
}

/* Input box on bot response */
.bot-response-input-box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(226, 226, 226, 0.1);
  flex: 1;
  border-radius: 4px;
  padding: 8px 10px;
  overflow: hidden;
}

.bot-response-input-box {
  padding: 0;
  border: 0;
  outline: none;
  width: 100%;
  height: auto;
  line-height: 1.5;
  font-family: sans-serif;
  font-size: 1em;
  resize: none;
  scrollbar-width: none;
  background-color: transparent;
  align-content: center;
  color: #fff;
}

.bot-response-input-box::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* Table */
table,
th,
tr,
td {
  border: 2px solid #fff;
  border-collapse: collapse;
}

.bot-response-table {
}

.bot-response-table-row {
  padding: 0;
  text-align: center;
}

.bot-response-table-header {
  /* background-color: rgba(0, 0, 0, 1); */
  padding: 10px;
  width: 300px;
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
}

.bot-response-table-col {
  padding: 10px;
}

.bot-response-submit-button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 1);
  color: #212121;
  margin-top: 20px;
}

/* SCROLL BAR STYLE */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 50px;
  border: 3px solid #2e2e2e;
}

::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

/* Welcome Message Style */

.welcome-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -150px;
  right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.welcome-message h1 {
  font-size: 2.5em; /* Adjust font size as needed */
  font-family: sans-serif;
  transition: 0.3s;
  color: rgba(0, 0, 0, 0.2); /* Adjust color as needed */
}

/* Media Query */
@media screen and (max-width: 750px) {
}
