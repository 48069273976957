/* CHAT TEXT BOX */
.input-container {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* align-items: center; */
  justify-content: center;
  margin: 0px 15%;
  gap: 20px;
  position: relative;
}

.input-text-area-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 18px 0;
  flex: 1;
  border-radius: 50px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #2e2e2e;
  /* overflow: hidden; */
}

.input-text-area-container.increased-height {
  border-radius: 25px;
}

.input-textbox {
  /* flex: 1; */
  margin-left: 50px;
  margin-right: 80px;
  height: auto;
  max-height: 250px;
  border: 0px;
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1.5;
  resize: none;
  align-content: center;
  background-color: transparent;
  outline: none;
  scrollbar-width: none;
  z-index: 1;
  color: #e9ecef;
  overflow: hidden;
}

.input-textbox.feedback {
  margin-left: 30px;
}

.input-textbox::placeholder {
  color: #7d7d7d;
}

.regenerate-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  z-index: 10;
}

.error-message-text {
  text-align: center;
  color: crimson;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}

.regenerate-message-container button {
  font-size: 0.8em;
  font-weight: 500;
  background-color: rgb(110, 110, 110);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
  color: #fff;
  border: 0;
  border-radius: 50px;
  padding: 10px 20px;
  transition: 0.3s;
}
.regenerate-message-container button:hover {
  background-color: rgba(39, 169, 225, 1);
  transition: 0.3s;
}

.input-text-area-buttons-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

.input-text-area-buttons-container.feedback {
  justify-content: flex-end;
}

.input-text-area-buttons-container.feedback .add-attachment-button {
  display: none;
}

.send-chat-button {
  height: 50px;
  width: 50px;
  margin: 0 10px 8px 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 50px;
  z-index: 2;
  cursor: pointer;
  transition: 0.3s;
}

.send-chat-button.disabled {
  background-color: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

.send-icon {
  color: #212121;
  font-size: 24px;
  transition: 0.3s;
}

.send-chat-button.disabled .send-icon {
  color: rgba(255, 255, 255, 0.4);
  transition: 0.3s;
}

.add-attachment-button {
  margin: 0 10px 8px 10px;
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  z-index: 2;
  cursor: pointer;
}

.add-attachment {
  color: #fff;
  font-size: 24px;
}

.attachment-popup {
  position: absolute;
  z-index: 999;
  background-color: #2e2e2e;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 10px 10px;
  /* max-width: 200px; */
  border-radius: 10px;
  top: -60px;
  left: 20px;
}

.attachment-popup p {
  font-size: 14px;
}

.attachment-list {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 10px;
  padding: 0px 10px;
}

.attachment-list:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.upload-icon {
  font-size: 22px;
}

.uploaded-file-container {
  display: flex;
  /* flex-direction: column; */
  padding: 0px 50px 10px 50px;
  gap: 10px;
}

.uploaded-file-image-container {
  height: 50px;
  width: 80px;
  /* overflow: hidden; */
  position: relative;
  z-index: 1;
}

.uploaded-file-image {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.uploaded-file-close-icon {
  font-size: 14px;
  padding: 2px;
  border-radius: 50px;
  background-color: #212121;
  box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.1);
  color: #fff;
  position: absolute;
  right: -5px;
  top: -5px;
}

.uploaded-file-item-container {
  background-color: #181818;
  box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.1);
  height: 50px;
  max-width: 300px;
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  color: #fff;
  z-index: 1;
}

.uploaded-file-detail {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 10px;
}

.uploaded-file-detail .icon-container {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 8px;
  display: flex;
}

.uploaded-file-detail .icon-container.doc {
  background-color: crimson;
}

.uploaded-file-detail .icon-container.unknown {
  background-color: goldenrod;
}

.uploaded-file-detail .file-name {
  font-size: 0.9em;
}

@media screen and (max-width: 750px) {
  .input-container {
    margin: 0px 10%;
  }
}
