.bot_container {
  overflow-y: auto;
  /* scrollbar-width: none; */
  padding: 0px 10rem;
}

.botui_message {
  flex: 1;
  overflow-y: auto;
}

.botui_message .human_content_box {
  margin: 5px 0;
}

.botui-message .loading {
  background-color: transparent !important;
}

.human_content_box {
  display: flex;
  justify-content: end;
}

.bot_content_box {
  display: flex;
  flex-direction: column;
}

.botui_message_content.human {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.human,
.bot {
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 75%;
  word-wrap: break-word;
  font-family: sans-serif;
}

.human {
  background-color: #2e2e2e;
  border-radius: 10px;
  float: right;
  font-size: 1em;
}

.human pre {
  padding: 0;
  margin: 0;
  color: #fff;
}

pre {
  white-space: pre-wrap;
  font-family: sans-serif;
  line-height: 2.5ch;
}

.human {
  color: #fff;
}

.bot {
  background-color: transparent;
  color: #fff;
}

.bot-response-graph-container {
  width: 800px;
  height: 400px;
}

.botui_message_content .attachment {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.botui_message_content .attachment .uploaded-file-image-container {
  height: 200px;
  /* max-height: 200px; */
  width: 100%;
}

.bot-conclude-message-container {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  gap: 10px;
  background-color: rgba(24, 24, 24, 1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  /* margin: 10px 15%; */
  margin-bottom: 10px;
}

.conclude-message-text {
  color: #fff;
  font-size: 0.9em;
  flex: 1;
  line-height: 20px;
}

.conclude-message-button-container {
  display: flex;
  gap: 10px;
}

.conclude-message-button {
  border: 0;
  height: 30px;
  padding: 5px 20px;
  border-radius: 50px;
  background-color: rgba(46, 46, 46, 1);
  color: #fff;
}

.conclude-message-button:hover {
  background-color: rgba(255,255,255,0.2);
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .bot_container {
    padding: 0px 1rem;
  }
}
