body {
  background-color: #212121;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
  overflow: hidden; /* Prevent scrolling when sidebar is open */
  font-family: sans-serif;
}
