.welcome-message,
.chat-display-container {
  margin-left: 280px;
  transition: 0.3s;
}

.sidebar {
  height: 100vh;
  width: 280px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #181818;
  overflow-x: hidden;
  padding-top: 0;
  transition: width 0.3s;
  /* scrollbar-width: thin; */
  z-index: 10;
}

.sidebar::-webkit-scrollbar {
  width: 12px;
}

.sidebar::-webkit-scrollbar-track {
  background: #2e2e2e;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border-radius: 50px;
  border: 3px solid #2e2e2e;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
}

.sidebar .conversation-topic-container {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  margin-bottom: 5px;
}

.sidebar a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  color: #f1f1f1;
  display: block;
  transition: 0s;
  font-family: sans-serif;
  /* letter-spacing: 0.1ch; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .conversation-topic-container:hover {
  color: #fff;
  background-color: #212121;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar .conversation-topic-container.highlight {
  background-color: #212121;
  color: white;
  font-weight: 600;
  border-radius: 5px;
}

.sidebar-top-btns {
  position: sticky;
  top: 0px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 5px;
  transition: left 0.3s;
  display: flex;
  background-color: #181818;
  width: 100%;
  justify-content: space-between;
}

/* Timeline container */
.timeline-container {
  margin-bottom: 30px;
}

.timeline-container.closed {
  display: none;
}

.timeline-text {
  color: #c0c0c0;
  font-family: sans-serif;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  padding: 0px 8px;
  letter-spacing: 0.2px;
  /* padding-bottom: 4px; */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.sidebar-top-btns h2 {
  color: #fff;
  font-family: sans-serif;
  font-size: 22px;
}

.button-container {
  display: flex;
  padding-right: 30px;
  padding-left: 10px;
  padding-top: 5px;
}

.open-btn {
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
}

.open-btn .menu-icon {
  font-size: 28px;
}

.create-new {
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
}

.create-new .edit-icon {
  font-size: 24px;
}

.sidebar.closed {
  width: 60px;
}
.sidebar.closed h2 {
  display: none;
}

.sidebar.closed a {
  display: none;
}
.sidebar-top-btns.closed {
  padding-left: 0;
}
.sidebar-top-btns.closed .button-container {
  flex-direction: column;
}

.open-btn.closed,
.create-new.closed {
  color: #fff;
}

.chat-container {
  transition: margin-left 0.3s;
}

.welcome-message {
  transition: margin-left 0.3s;
}

.chat-container.closed {
  margin-left: 0 !important;
}

.chat-display-container.closed {
  margin-left: 60px !important;
  transition: 0.3s;
}

.welcome-message.closed {
  margin-left: 0 !important;
}

@media screen and (max-width: 750px) {
  .welcome-message,
  .chat-display-container {
    margin-left: 0;
    margin-top: 40px;
    margin-right: 0;
    width: 100%;
    border-radius: 0px;
  }

  .chat-container {
    margin-top: 20px;
  }

  .welcome-message {
    left: 0;
  }

  .welcome-message h1 {
    font-size: 1.5rem;
    transition: 0.3s;
  }

  .sidebar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #181818;
    overflow-x: hidden;
    padding-top: 0px;
    transition: 0.3s;
    scrollbar-width: none;
  }

  .sidebar.closed {
    height: 0;
    width: 100%;
    transition: 0.3s;
  }

  .sidebar.closed h2 {
    display: flex;
  }

  .sidebar.closed a {
    display: flex;
  }

  .sidebar-top-btns.closed .button-container {
    flex-direction: row;
  }

  .sidebar-top-btns {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #181818 !important;
  }

  .sidebar-top-btns.closed {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);

    padding-left: 20px;
    position: fixed;
    background-color: #181818;
    justify-content: space-between;
  }

  .chat-display-container.closed {
    margin-left: 0 !important;
    transition: 0.3s;
  }
}
